import PromoLayout from 'layouts/PromoLayout';
import React, { lazy } from 'react';

const SubscribeContent = lazy(() => import('content/Subscribe'));

const Subscribe = () => {
	const description =
		'Подписывайтесь на email-рассылку «Новой газеты» и получите «Ту самую "Новую газету"» в PDF-формате';
	return (
		<PromoLayout title='Вам «Конверт»<br/>с подарком' description={description}>
			<SubscribeContent />
		</PromoLayout>
	);
};

export default Subscribe;

import React from 'react';
import IndexPage from 'pages/Index';
import NOPromo from 'pages/NOPromo';
import Page404 from 'pages/Page404';
import Subscribe from 'pages/Subscribe';
import Newsletters from 'pages/Newsletters';
import Service from 'pages/Service';

const RenderPage = ({ path = 'index' }) => {
	switch (path) {
		case 'index':
			// при локальной разработке подставить компонент нужной страницы
			return <IndexPage />;
		// return <NOPromo />; // пример подстановки
		case 'promo':
			return <NOPromo />;
		case 'subscribe':
			return <Subscribe />;
		case 'newsletters':
			return <Newsletters />;
		case 'terms':
			return <Service type={path} />;
		default:
			return <Page404 />;
	}
};

export default RenderPage;

import React, { lazy } from 'react';
import MainContainer from 'components/MainContainer';
import MainLayout from 'layouts/MainLayout';

const ServiceContent = lazy(() => import('content/Service'));

const Newsletters = ({ type }) => (
	<MainLayout>
		<MainContainer>
			<ServiceContent type={type} />
		</MainContainer>
	</MainLayout>
);
export default Newsletters;

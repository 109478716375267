import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null, reloaded: false };
	}

	componentDidCatch(error, errorInfo) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		const { reloaded } = this.state;
		if (error.message && chunkFailedMessage.test(error.message) && !reloaded) {
			this.setState((prev) => ({
				...prev,
				reloaded: true
			}));
			window.location.reload();
			return;
		}
		// Catch errors in any components below and re-render with error message
		this.setState((prev) => ({
			...prev,
			error,
			errorInfo
		}));
		// You can also log error messages to an error reporting service here
	}

	render() {
		const { errorInfo, error } = this.state;
		const { children } = this.props;

		if (errorInfo) {
			// Error path
			return (
				<div>
					<h2>Что-то пошло не так</h2>

					{error && error.toString()}
				</div>
			);
		}
		// Normally, just render children
		return children;
	}
}

export default ErrorBoundary;

import PromoLayout from 'layouts/PromoLayout';
import React, { lazy } from 'react';

const NOPromoContent = lazy(() => import('content/NOPromo'));

const NOPromo = () => (
	<PromoLayout page='promo'>
		<NOPromoContent />
	</PromoLayout>
);
export default NOPromo;

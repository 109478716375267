import { defaultPage } from 'api';
import { useEffect } from 'react';

const Page404 = () => {
	useEffect(() => {
		window.location.href = defaultPage;
	}, []);

	return null;
};
export default Page404;

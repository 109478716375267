import React from 'react';
import styles from './index.module.scss';
import Registered from './Registered';

const Footer = () => (
	<footer className={styles.root}>
		<Registered />
	</footer>
);

export default Footer;

import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

const MainContainer = ({ children, className }) => (
	<div className={cn(styles.container, className)} id='mainContainer'>
		{children}
	</div>
);

export default MainContainer;

import React from 'react';
import styles from './index.module.scss';

const PromoContainer = ({ children }) => (
	<div className={styles.root}>
		<div className={styles.container}>
			<div className={styles.content}>{children}</div>
		</div>
	</div>
);

export default PromoContainer;

import React from 'react';
import styles from './index.module.scss';
// import useWindowSize from 'hooks/useWindowSize';

const PromoHeaderContent = () => {
	// const { width } = useWindowSize();
	// const isMobile = width < 781;

	return (
		<>
			<h1 className={styles.title}>
				Полная коллекция журналов <br />
				«Новой рассказ-газеты» <br />и номеров <br className={styles.brMobile} />
				«Новой газеты»
				{/* «Новая газета Еженедельник». 
				Первый выпуск */}
			</h1>
			{/* <p className={styles.subtitle}>
				Покупайте {isMobile && <br />}новый журнал {isMobile && <br />}по специальной цене{' '}
				<b>200 рублей.</b> Только для соучастников!
			</p> */}

			{/* <img src='/magazineCover_NO_v14_v2.jpg' alt='Обложка журнала' className={styles.magazineCover} /> */}
			<img
				src='/banner.webp'
				alt='Обложка коллекции'
				className={styles.cover}
				width={1147}
				height={1349}
			/>
		</>
	);
};

export default PromoHeaderContent;

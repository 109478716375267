import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './index.module.scss';

const DonateButton = ({ color, text, site, href, className, onClick = () => {} }) => {
	const to = {
		pathname: '/donate',
		search: '?utm_source=site&utm_medium=header&utm_campaign=web'
	};

	return href ? (
		<a
			href={href}
			className={cx(styles.button, styles[color], styles[site], className)}
			target='_blank'
			rel='noreferrer'
			onClick={onClick}
		>
			<span>{text}</span>
		</a>
	) : (
		<Link
			to={to}
			className={cx(styles.button, styles[color], styles[site], className)}
			onClick={onClick}
		>
			<span>{text}</span>
		</Link>
	);
};

export default DonateButton;

import React, { Suspense } from 'react';
import Footer from './Footer';
import ErrorBoundary from 'components/ErrorBoundary';
import Header from './Header';
import PromoContainer from 'components/PromoContainer';
import styles from './index.module.scss';

const PromoLayout = ({ children, ...rest }) => (
	<>
		<Header {...rest} />
		<div className={styles.container}>
			<PromoContainer>
				<ErrorBoundary>
					<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>{children}</Suspense>
				</ErrorBoundary>
			</PromoContainer>
		</div>
		<Footer />
	</>
);

export default PromoLayout;

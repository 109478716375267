import React, { Suspense } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from './Footer';
import Header from './Header';

const MainLayout = ({ children }) => (
	<>
		<Header />
		<ErrorBoundary>
			<Suspense fallback={<div style={{ minHeight: '100vh' }} />}>{children}</Suspense>
		</ErrorBoundary>
		<Footer />
	</>
);

export default MainLayout;

import React from 'react';
import ScrollMenu from './ScrollMenu';
import DonateButton from 'components/Buttons/DonateButton';
import styles from './index.module.scss';

const Header = () => {
	return (
		<ScrollMenu className={styles.header}>
			<div className={styles.root}>
				<figure className={styles.figure}>
					<img src='/ic_logo.svg' alt='logo' className={styles.svg} />
				</figure>

				<strong className={styles.strong}>
					<span>Новая газета</span>
				</strong>
			</div>
			<DonateButton
				href='https://supportnovaya.media/'
				color='yellow'
				text='Стать со-участником'
				className={styles.donateBtn}
			/>
		</ScrollMenu>
	);
};

export default Header;

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

const ScrollMenu = ({ children, className }) => {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		let prevScrollPosition = window.scrollY;

		document.documentElement.style.setProperty('--headerBottomPosition', '60px');

		const handleScroll = () => {
			const currentScrollPosition = window.scrollY;

			let isVisible = false;

			if (prevScrollPosition > currentScrollPosition) isVisible = true;

			setIsVisible(isVisible);

			document.documentElement.style.setProperty(
				'--headerBottomPosition',
				`${isVisible ? 60 : 5}px`
			);

			prevScrollPosition = currentScrollPosition;
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<header className={cx(styles.root, { [styles.hidden]: !isVisible }, className)} id='mainHeader'>
			{children}
		</header>
	);
};

export default ScrollMenu;

import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import PromoHeaderContent from './PromoHeaderContent';

const Header = ({ page, title, description }) => {
	const renderContent = (page) => {
		switch (page) {
			case 'promo':
				return <PromoHeaderContent />;

			default:
				return (
					<div className={styles.header}>
						<h1 dangerouslySetInnerHTML={{ __html: title }} />
						<h2>{description}</h2>
					</div>
				);
		}
	};

	return (
		<div className={cn(styles.root, styles[page])}>
			<span
				style={{ backgroundImage: 'url(/pattern_NO.svg)' }}
				className={styles.root_background}
			/>
			<div className={styles.container}>{renderContent(page)}</div>
		</div>
	);
};

export default Header;

import React from 'react';
import { ToastContainer } from 'react-toastify';
import './index.module.scss';

const Toast = () => (
	<ToastContainer
		position='top-center'
		autoClose={2000}
		hideProgressBar
		newestOnTop
		closeOnClick
		draggable
		pauseOnHover
		limit={2}
	/>
);

export default Toast;

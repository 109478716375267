import { defaultPage } from 'api';
import { useEffect } from 'react';

const IndexPage = () => {
	useEffect(() => {
		window.location.href = defaultPage;
	}, []);

	return null;
};
export default IndexPage;

import Toast from 'components/Toast';
import RenderPage from 'RenderPage';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import styles from './styles/index.module.scss';

function App(props) {
	return (
		<main className={styles.root} id='mainNode'>
			<RenderPage {...props} />
			<Toast />
		</main>
	);
}

export default App;

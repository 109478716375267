import MainLayout from 'layouts/MainLayout';
import React, { lazy } from 'react';

const NewslettersContent = lazy(() => import('content/Newsletters'));

const Newsletters = () => (
	<MainLayout>
		<NewslettersContent />
	</MainLayout>
);
export default Newsletters;

import React from 'react';
import styles from './index.module.scss';

const Footer = () => (
	<footer className={styles.root}>
		<span style={{ backgroundImage: 'url(/pattern_NO.svg)' }} className={styles.root_background} />
	</footer>
);

export default Footer;
